import React from "react";
import { Link, useSearchParams } from "react-router";
import { RaterSurveyType } from "../../types";
import { Envelope, Pencil } from "../../icons";
import { SurveyStatusBadge } from "../SurveyStatusBadge";

export const RaterProgressTable: React.FC<{ raterList: Array<RaterSurveyType> }> = ({ raterList }) => {
  const [searchParams] = useSearchParams();
  const surveyInstanceSecretKey = searchParams.get("k");

  return (
    <table className="min-w-full">
      <tbody className="divide-y divide-secondary-border bg-white">
        {raterList.map((rater) => (
          <tr className="even:bg-secondary-light flex flex-col sm:table-row" key={rater.surveyInstanceUuid}>
            <td className="py-2 px-3">
              <div className="font-medium text-dark me-2">
                {rater.forename} {rater.surname}
              </div>
              <div className="text-sm text-secondary me-2 first-letter:capitalize">{rater.label}</div>
            </td>

            <td className="ps-2 pe-3">
              <SurveyStatusBadge rater={rater} />
            </td>

            <td>
              <div className="flex flex-col sm:flex-row sm:justify-end pt-2 sm:pt-1 pb-1">
                {rater.surveyStatus !== "COMPLETE" && rater.deliveryStatus === "DELIVERY" && (
                  <div className="px-2 py-1 sm:p-0 sm:pe-3">
                    <Link
                      to={{
                        pathname: "/send-reminder",
                        search: "?k=" + surveyInstanceSecretKey + "&s=" + rater.surveyInstanceUuid,
                      }}
                      className="btn btn-primary text-xs w-full sm:w-auto"
                    >
                      <div className="flex flex-row justify-center">
                        <Envelope className="w-4 me-2" />
                        <div>Send reminder</div>
                      </div>
                    </Link>
                  </div>
                )}

                <div className="px-2 py-1 sm:p-0 sm:pe-3 grow sm:grow-0">
                  <Link
                    to={{
                      pathname: "/edit-rater",
                      search: "?k=" + surveyInstanceSecretKey + "&s=" + rater.surveyInstanceUuid,
                    }}
                    className="btn btn-primary text-xs inline-block w-full sm:w-auto"
                  >
                    <div className="flex flex-row justify-center">
                      <Pencil className="w-4 me-2" />
                      <div>Edit</div>
                    </div>
                  </Link>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default RaterProgressTable;
