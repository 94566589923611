import React from "react";
import { RaterSurveyType, StatusColor } from "../../types";
import Badge from "../Badge/Badge";
import { SurveyStatus } from "@careerinsight/applib-common/entity";

export const SurveyStatusBadge: React.FC<{
  rater: RaterSurveyType;
}> = ({ rater }) => {
  return (
    <Badge className="text-nowrap" size="sm" status={getStatus(rater).color} title={getStatus(rater).title}>
      &nbsp;{getStatus(rater).label}&nbsp;
    </Badge>
  );
};

type StatusAttributes = { label: string; color: StatusColor; title: string };

const getStatus = (rater: RaterSurveyType): StatusAttributes => {
  if (!rater.deliveryStatus) {
    return {
      label: "Invitation scheduled",
      color: "secondary",
      title: "The nominated rater will soon be sent an invitation to give you feedback. Invite emails are sent hourly",
    };
  }

  if (["BOUNCE_PERMANENT", "BOUNCE_TRANSIENT"].includes(rater.deliveryStatus)) {
    return {
      label: "Email delivery failed",
      color: "danger",
      title:
        "Email delivery to this rater was unsuccessful. The email address may not exist, or be incorrectly spelled. Please delete and re-add the rater with a correct email address",
    };
  }

  const noResponse: StatusAttributes = {
    label: "No response yet",
    color: "secondary",
    title: "The nominated rater has not yet clicked on their invitation email and started giving feedback",
  };

  const surveyStatusLabels: Record<SurveyStatus, StatusAttributes> = {
    COMPLETE: { label: "Completed", color: "success", title: "The nominated rater has completed their feedback" },
    CREATED: noResponse,
    NO_RESPONSE: noResponse,
    PARTIALLY_COMPLETE: {
      label: "Partially completed",
      color: "primary",
      title: "The nominated rater has begun giving feedback but has not yet completed the task",
    },
    VISITED: noResponse,
  };

  return surveyStatusLabels[rater.surveyStatus];
};

export default SurveyStatusBadge;
