import React from "react";
import { Size, StatusColor } from "../../types";

const colorVariants: Record<StatusColor, string> = {
  danger: "bg-danger",
  info: "bg-info",
  primary: "bg-primary",
  secondary: "bg-secondary",
  success: "bg-success",
  warning: "bg-warning",
};

const sizeVariants: Record<Size, string> = {
  xl: "text-xl",
  lg: "text-lg",
  md: "text-md",
  sm: "text-sm",
  xs: "text-xs",
};

export const Badge: React.FC<{
  children: React.ReactNode;
  status?: StatusColor;
  size?: Size;
  className?: string;
  title?: string;
}> = ({ children, status = "primary", size = "xs", className = "", title }) => {
  return (
    <div
      className={`inline-flex items-center rounded-full ${colorVariants[status]} px-2 py-1 ${
        sizeVariants[size]
      } font-medium text-white ${className} ${title && "cursor-help"}`}
      title={title}
    >
      {children}
    </div>
  );
};

export default Badge;
