import React from "react";

export const Trash: React.FC<{ className?: string }> = ({ className = "" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={className}>
    <g stroke="currentColor" fill="none" strokeWidth="32">
      <path d="m112 112 20 320c1 18.5 14.4 32 32 32h184c17.7 0 30.9-13.5 32-32l20-320" />
      <path d="M192 112V72h0a24 24 0 0 1 24-24h80a24 24 0 0 1 24 24h0v40m-64 64v224m-72-224 8 224m136-224-8 224" />
      <path d="M80 112h352" />
    </g>
  </svg>
);
