import { InfoCard } from "../InfoCard";
import React from "react";
import { RespondentHomepageResponse, SurveyStatus } from "@careerinsight/applib-common/entity";
import { Link, useSearchParams } from "react-router";

const completeLabels = { title: "Your survey is complete", buttonLabel: "Revisit survey >>" };
const incompleteLabels = { title: "Please complete your survey", buttonLabel: "Proceed to survey >>" };

const labels: Record<SurveyStatus, { title: string; buttonLabel: string }> = {
  COMPLETE: completeLabels,
  CREATED: incompleteLabels,
  NO_RESPONSE: incompleteLabels,
  PARTIALLY_COMPLETE: incompleteLabels,
  VISITED: incompleteLabels,
};

export const YourSurveyCard: React.FC<{ model: RespondentHomepageResponse }> = ({ model }) => {
  const [searchParams] = useSearchParams();
  const surveyInstanceSecretKey = searchParams.get("k");
  const isDisabled = model.surveyInstance.isExpired || model.surveyInstance.isLocked || model.surveyInstance.isRedacted;
  const isComplete = model.surveyInstance.surveyStatus === "COMPLETE";

  return (
    <InfoCard>
      <div>
        <h3 className="text-xl font-semibold">Your survey</h3>

        {isComplete && <p className="mt-4">Your survey is complete thank you!</p>}
        {!isDisabled && <p className="mt-3">You can still revisit your survey to make any last-minute changes.</p>}
        {isDisabled && <p className="mt-3">Your survey is locked and can no longer be edited.</p>}
      </div>

      {isDisabled || (
        <Link
          target="survey"
          to={{ pathname: "/", search: "?k=" + surveyInstanceSecretKey }}
          className="btn btn-primary mt-8"
        >
          {labels[model?.surveyInstance.surveyStatus].buttonLabel}
        </Link>
      )}
    </InfoCard>
  );
};

export default YourSurveyCard;
