import React, { useEffect } from "react";
import { RespondentHomepageResponse } from "@careerinsight/applib-common/entity";
import { appSettings, getErrorMessage } from "../../index";
import { NominatedRater } from "@careerinsight/applib-common/zod";
import * as raterRepo from "../../repo/RaterRepo";

/**
 * RespondentHomepageContext
 */
export const RespondentHomepageContext = React.createContext<{
  model?: RespondentHomepageResponse;
  isLoading: boolean;
  error?: Error;
  nominateRater: (nominatedRater: NominatedRater) => Promise<Response>;
  sendReminderMessage: (props: { message: string; raterSurveyInstanceUuid: string }) => Promise<Response>;
  updateRater: (props: {
    forename: string;
    surname: string;
    raterType: string;
    raterSurveyInstanceUuid: string;
  }) => Promise<Response>;
  deleteRater: (raterSurveyInstanceUuid: string) => Promise<Response>;
}>(null as any);

export const RespondentHomepageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [model, setModel] = React.useState<RespondentHomepageResponse>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Error>();
  const [lastUpdated, setLastUpdated] = React.useState(new Date().getTime());

  const urlParams = new URLSearchParams(window.location.search);
  const surveyInstanceSecretKey = urlParams.get("k")!;

  /**
   * Load data for the respondent homepage
   */
  useEffect(
    function startUp() {
      if (!surveyInstanceSecretKey) {
        setError(new Error("Missing survey key"));
        setIsLoading(false);
      } else {
        (async () => {
          const res = await fetch(`${appSettings.surveyApi}/surveys/${surveyInstanceSecretKey}/respondent-homepage`);
          if (!res.ok) setError(new Error(getErrorMessage(await res.json())));
          else setModel(await res.json());
          setIsLoading(false);
        })();
      }

      return () => {
        // Clean-up code
      };
    },
    [lastUpdated],
  );

  /**
   * Submit nominated rater
   */
  const nominateRater = async (nominatedRater: NominatedRater) => {
    const response = await raterRepo.submitNominatedRater(surveyInstanceSecretKey, nominatedRater);

    // Trigger a refresh of the respondent homepage data
    setLastUpdated(new Date().getTime());

    return response;
  };

  /**
   * Delete an existing rater
   */
  const deleteRater = async (raterSurveyInstanceUuid: string) => {
    const response = await raterRepo.deleteRater(surveyInstanceSecretKey, raterSurveyInstanceUuid);

    // Trigger a refresh of the respondent homepage data
    setLastUpdated(new Date().getTime());

    return response;
  };

  /**
   * Send reminder message
   */
  const sendReminderMessage = async (props: { message: string; raterSurveyInstanceUuid: string }) => {
    const response = await raterRepo.sendReminderMessage(surveyInstanceSecretKey, props);

    // Trigger a refresh of the respondent homepage data
    setLastUpdated(new Date().getTime());

    return response;
  };

  /**
   * Update rater details
   */
  const updateRater = async (props: {
    forename: string;
    surname: string;
    raterType: string;
    raterSurveyInstanceUuid: string;
  }) => {
    const response = await raterRepo.updateRater(surveyInstanceSecretKey, props);

    // Trigger a refresh of the respondent homepage data
    setLastUpdated(new Date().getTime());

    return response;
  };

  return (
    <RespondentHomepageContext.Provider
      value={{ model, isLoading, error, nominateRater, sendReminderMessage, updateRater, deleteRater }}
    >
      {children}
    </RespondentHomepageContext.Provider>
  );
};
