import React from "react";

export const Floppy: React.FC<{ className?: string }> = ({ className = "" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className={className}>
    <g fill="currentColor">
      <path d="M43.23 5h11.54a.98.98 0 0 1 .98.98v24.29a.98.98 0 0 1-.98.98H43.23a.98.98 0 0 1-.98-.98V5.98a.98.98 0 0 1 .98-.98Z" />
      <path d="M93.55 25.03 74.97 6.45A4.95 4.95 0 0 0 71.47 5h-7.02v27.4a3.37 3.37 0 0 1-3.37 3.36H24.37A3.37 3.37 0 0 1 21 32.39V5H10.5A5.5 5.5 0 0 0 5 10.5v79a5.5 5.5 0 0 0 5.5 5.5h79a5.5 5.5 0 0 0 5.5-5.5s0-50.14 0-60.97a4.94 4.94 0 0 0-1.45-3.5ZM79 82.98a3.37 3.37 0 0 1-3.37 3.37H24.37A3.37 3.37 0 0 1 21 82.98V46.87a3.37 3.37 0 0 1 3.37-3.37h51.26A3.37 3.37 0 0 1 79 46.87Z" />
    </g>
  </svg>
);
