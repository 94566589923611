import React from "react";

export const AlertBox: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="bg-danger shadow-xs rounded-lg p-3 text-white font-semibold flex items-center">
      <div className="me-2 hidden sm:block">
        <svg width="22px" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
      </div>

      <div className="ps-2">{children}</div>
    </div>
  );
};
