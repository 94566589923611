import React from "react";

export const Pencil: React.FC<{ className?: string }> = ({ className="" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className={className}>
    <path
      d="M5 15H1v-4l9.414-9.414a2 2 0 0 1 2.828 0l1.172 1.172a2 2 0 0 1 0 2.828L5 15zm-1.66-3.512l1.172 1.172 5.904-5.904-1.172-1.172-5.904 5.904zM13 4.172l-1.17 1.17-1.172-1.172L11.828 3 13 4.172z"
      fillRule="evenodd"
      fill="currentColor"
    />
  </svg>
);
