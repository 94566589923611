import { RequiredRaters, RespondentHomepageResponse } from "@careerinsight/applib-common/entity";
import { RequiredRaterType } from "./types";

/**
 * Returns a copy of 'array', with the items shuffled
 * Taken from https://stackoverflow.com/a/2450976/226513
 */
export const shuffle = <T>(array: T[]) => {
  const outputArray = [...array];
  let currentIndex = outputArray.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [outputArray[currentIndex], outputArray[randomIndex]] = [outputArray[randomIndex]!, outputArray[currentIndex]!];
  }

  return outputArray;
};

/**
 * Given the error response of a fetch invocation, this function returns a string
 */
export const getErrorMessage = (err: any): string => {
  if (!err) return "";
  if (err.message) return err.message;
  return JSON.stringify(err);
};

/**
 * Sets a query string parameter in the URL
 * @param name
 * @param value
 * @param replace if set to false, then this action will add to the browser history - i.e. it will go forwards one page,
 * and you'll be able to press the browser back button.  If set to true, then the URL will be updated without affecting
 * the browser history.
 */
export function setQueryParameter(name: string, value: string, replace = false) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(name, value);
  const newRelativeUrl = window.location.pathname + "?" + searchParams.toString();
  if (replace) window.history.replaceState({ name: value }, "", newRelativeUrl);
  else window.history.pushState({ name: value }, "", newRelativeUrl);
}

/**
 * Returns a count of Rater who are still required, keyed by raterType
 */
export const compileRemainingRequiredRaterCount = (model: RespondentHomepageResponse) => {
  // Compose a map of raterType to minCount
  const raterTypeToMinCountMap = new Map<string, number>();
  for (const rr of model.requiredRaters!) {
    raterTypeToMinCountMap.set(rr.raterType, rr.minCount);
  }

  // Adjust the minCount for the raterTypes that have already been nominated
  for (const raterSurvey of model.raterSurveyInstances!) {
    const minCount = raterTypeToMinCountMap.get(raterSurvey.raterType!);
    if (minCount) raterTypeToMinCountMap.set(raterSurvey.raterType!, minCount - 1);
  }

  return raterTypeToMinCountMap;
};

/**
 * Returns a list of required Raters
 */
export const compileRequiredRaterList = (model: RespondentHomepageResponse): Array<RequiredRaterType> => {
  const requiredRaterList: Array<RequiredRaterType> = [];
  const remainingRequiredRaterCount = compileRemainingRequiredRaterCount(model);

  for (const rr of model.requiredRaters!) {
    // Set mandatoryCount to minCount minus the number of raters already nominated
    const mandatoryCount = remainingRequiredRaterCount.get(rr.raterType!)!;

    // Set optionalCount to maxCount minus the number of raters already nominated
    const optionalCount =
      rr.maxCount - model.raterSurveyInstances!.filter((rs) => rs.raterType === rr.raterType).length;

    // Set isVisible to false if the count of raters already nominated is greater than or equal to maxCount
    const isVisible = model.raterSurveyInstances!.filter((rs) => rs.raterType === rr.raterType).length < rr.maxCount;

    if (isVisible) {
      requiredRaterList.push({
        label: rr.label,
        raterType: rr.raterType,
        mandatoryCount,
        optionalCount,
      });
    }
  }

  // Order the list by mandatoryCount
  requiredRaterList.sort((a, b) => b.mandatoryCount - a.mandatoryCount);

  return requiredRaterList;
};

/**
 * Composes a map of raterType to label
 */
export const raterTypeToLabelMap = (requiredRaters: RequiredRaters): Map<string, string> => {
  const map = new Map<string, string>();
  requiredRaters.forEach((rater) => map.set(rater.raterType, rater.label));
  return map;
};
