import React from "react";
import {
  EditRater,
  Homepage,
  HomepageWrapper,
  NominateRater,
  RespondentHomepageProvider,
  SendReminder,
  SurveyApp,
  SurveyHeader,
  SurveyProvider,
  ValidationProvider,
} from "@careerinsight/applib-react";
import { BrowserRouter, Route, Routes } from "react-router";

function App() {
  const surveyApp = (
    <SurveyProvider>
      <ValidationProvider>
        <SurveyApp>
          <SurveyHeader />
        </SurveyApp>
      </ValidationProvider>
    </SurveyProvider>
  );

  const respondentHomepage = (
    <RespondentHomepageProvider>
      <HomepageWrapper>
        <Homepage />
      </HomepageWrapper>
    </RespondentHomepageProvider>
  );

  const nominateRater = (
    <RespondentHomepageProvider>
      <HomepageWrapper>
        <NominateRater />
      </HomepageWrapper>
    </RespondentHomepageProvider>
  );

  const editRater = (
    <RespondentHomepageProvider>
      <HomepageWrapper>
        <EditRater />
      </HomepageWrapper>
    </RespondentHomepageProvider>
  );

  const sendReminder = (
    <RespondentHomepageProvider>
      <HomepageWrapper>
        <SendReminder />
      </HomepageWrapper>
    </RespondentHomepageProvider>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={surveyApp} />
        <Route path="homepage" element={respondentHomepage} />
        <Route path="nominate-rater" element={nominateRater} />
        <Route path="edit-rater" element={editRater} />
        <Route path="send-reminder" element={sendReminder} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
