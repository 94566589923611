import { Answers as AnswersType, SurveyPage as SurveyPageType } from "@careerinsight/applib-common/entity";
import { getErrorMessage } from "../util.js";
import { appSettings } from "../index";

export async function fetchSurveyAndResponse(surveyInstanceSecretKey: string): Promise<{
  surveyDefinition: SurveyPageType[];
  surveyResponse: AnswersType;
  model: Record<string, unknown>;
}> {
  const response = await fetch(appSettings.surveyApi + "/surveys/" + surveyInstanceSecretKey);
  if (response.ok) return await response.json();
  throw new Error(getErrorMessage(await response.json()));
}
