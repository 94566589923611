import React, { useContext, useEffect, useState } from "react";
import { SurveyContext, ValidationContext } from "../../providers";
import {
  AlertBox,
  PercentageBar,
  SurveyError,
  SurveyFinished,
  SurveyFooter,
  SurveyLoading,
  SurveyPage,
} from "../index.js";
import { appSettings } from "../../app-settings";

export const SurveyApp: React.FC<{ children: React.ReactNode; pageFooter?: React.JSX.Element }> = ({
  children,
  pageFooter,
}) => {
  const [isFinished, setIsFinished] = useState(false);

  const {
    surveyDefinition,
    flushPendingAnswers,
    hasPendingAnswers,
    isLoading,
    error,
    answers,
    setSurveyComplete,
    pageNumber,
    previousPage,
    nextPage,
  } = useContext(SurveyContext);

  const { validationState, setShowValidation, showValidation } = useContext(ValidationContext);

  // Warn the user if they have unsaved data
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasPendingAnswers()) {
        flushPendingAnswers();
        event.preventDefault();
        event.returnValue = "true";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasPendingAnswers]);

  /**
   * Click the finish button on the final page
   */
  function finishSurvey() {
    setSurveyComplete();
    flushPendingAnswers();
    setIsFinished(true);
  }

  /**
   * Navigate to the next page
   */
  function previousPageClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowValidation(false);
    previousPage();
  }

  /**
   * Navigate to the next page
   */
  function nextPageClick() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowValidation(false);

    // Only proceed if the current page is valid
    let isPageValid = true;

    for (const questionKey in validationState) {
      isPageValid = isPageValid && validationState[questionKey]!;
    }

    if (isPageValid) {
      const isLastPage = nextPage();
      if (isLastPage) setSurveyComplete();
    } else setShowValidation(true);
  }

  let appChildren: React.ReactNode;
  let pageCount = 0;
  let showFooter = false;

  if (isFinished) {
    // Show the finish page
    appChildren = (
      <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
        <div className="p-6 space-y-4">
          <SurveyFinished />
        </div>
      </div>
    );
  } else if (isLoading) {
    // Show the loading spinner
    appChildren = (
      <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
        <div className="p-6 space-y-4">
          <SurveyLoading />
        </div>
      </div>
    );
  } else if (surveyDefinition.length) {
    // Show the survey
    const page = surveyDefinition[pageNumber]!;
    pageCount = surveyDefinition.length;
    showFooter = true;

    appChildren = (
      <>
        <div className="pb-3">
          <PercentageBar />
        </div>

        <div key={page.uniqueId} className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
          <div className="py-3 px-1 sm:px-6 space-y-4">
            {showValidation && (
              <div className="py-3">
                <AlertBox>Some questions are mandatory and should be completed before you can progress</AlertBox>
              </div>
            )}

            <SurveyPage page={page} />
          </div>
        </div>
      </>
    );
  } else {
    // Show the error screen
    const errorMessage = error?.message ? error?.message : "Unknown error";
    appChildren = (
      <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
        <div className="p-6 space-y-4">
          <SurveyError errorMessage={errorMessage} />
        </div>
      </div>
    );
  }

  return (
    <div className="sm:bg-background">
      <div className="max-w-4xl mx-auto">
        <div className="min-h-screen sm:pt-3 pb-12 mx-3">
          <div className="pb-3">
            {/* Survey header - passed in as children */}
            {children}
          </div>
          <div className="pb-3">{appChildren}</div>

          {showFooter && (
            <div className="pb-3">
              <SurveyFooter
                pageNumber={pageNumber}
                previousPage={previousPageClick}
                nextPage={nextPageClick}
                pageCount={pageCount}
                finishSurvey={finishSurvey}
              />
            </div>
          )}

          {appSettings.showAnswers && (
            <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder mb-6">
              <div className="p-6 space-y-4">
                <h1 className="text-primary text-3xl font-bold">Answers</h1>
                <pre>{JSON.stringify(answers, null, 2)}</pre>

                <h1 className="text-primary text-3xl font-bold">Validation State</h1>
                <pre>{JSON.stringify(validationState, null, 2)}</pre>
              </div>
            </div>
          )}

          {answers.SYS_PREVIEW_MODE === true && (
            <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
              <div className="p-6 space-y-4">
                <h1 className="text-primary text-3xl font-bold">Preview mode</h1>
                <p>Responses will not be saved to the database</p>
              </div>
            </div>
          )}

          {pageFooter && pageFooter}
        </div>
      </div>
    </div>
  );
};

export default SurveyApp;
