import React, { useContext } from "react";
import { YourSurveyCard } from "../YourSurveyCard";
import { RaterProgressCard } from "../RaterProgressCard";
import { NominateRatersCard } from "../NominateRatersCard";
import { RespondentHomepageContext } from "../../providers";

export const Homepage: React.FC = ({}) => {
  const props = useContext(RespondentHomepageContext);
  const model = props.model!;

  return (
    <>
      <h1 className="text-3xl font-bold">
        {model.surveyProject.surveyProjectLabel} for {model.forename} {model.surname}
      </h1>

      <p className="text-xl">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi reprehenderit sapiente vero.
      </p>

      <div className="grid grid-cols-1 gap-8 md:max-w-4xl md:grid-cols-2">
        <YourSurveyCard model={model} />
      </div>

      {model.surveyProject.is360 && (
        <>
          <NominateRatersCard model={model} />
          <RaterProgressCard model={model} />
        </>
      )}
    </>
  );
};

export default Homepage;
