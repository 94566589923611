import React from "react";
import { Link, useSearchParams } from "react-router";
import { RequiredRaterType } from "../../types";
import { Person } from "../../icons";

export const NominateRatersTable: React.FC<{ requiredRaterList: Array<RequiredRaterType> }> = ({
  requiredRaterList,
}) => {
  const [searchParams] = useSearchParams();
  const surveyInstanceSecretKey = searchParams.get("k");

  return (
    <table className="min-w-full">
      <tbody className="divide-y divide-secondary-border bg-white">
        {requiredRaterList.map((rater) => (
          <tr className="even:bg-secondary-light flex flex-col sm:table-row" key={rater.raterType}>
            <td className="py-2 px-3">
              <span className="font-medium text-dark me-2 inline-block first-letter:capitalize">{rater.label}</span>
            </td>
            <td className="py-2 px-3">
              {rater.mandatoryCount > 0 && (
                <span className="text-danger font-semibold">
                  Please add at least {rater.mandatoryCount} more rater{rater.mandatoryCount === 1 ? "" : "s"} in this
                  category
                </span>
              )}

              {rater.optionalCount > 0 && !rater.mandatoryCount && (
                <>
                  <span className="sm:hidden">
                    You have nominated sufficient raters in this category, but you may nominate more if you wish
                  </span>

                  <span
                    className="hidden sm:block cursor-help"
                    title="You have nominated sufficient raters in this category, but you may nominate more if you wish"
                  >
                    Optional
                  </span>
                </>
              )}
            </td>
            <td className="relative whitespace-nowrap p-2 pr-3 text-right">
              <Link
                to={{
                  pathname: "/nominate-rater",
                  search: "?k=" + surveyInstanceSecretKey + "&t=" + rater.raterType,
                }}
                className={`btn ${rater.mandatoryCount > 0 ? "btn-danger" : "btn-primary"} text-xs w-full sm:w-auto`}
              >
                <div className="flex flex-row justify-center">
                  <Person className="w-4 me-2" />
                  <div>Nominate</div>
                </div>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default NominateRatersTable;
