import React from "react";

export const PaperPlane: React.FC<{ className?: string }> = ({ className = "" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 28 28" className={className}>
    <path
      fill="currentColor"
      d="M 3.59375 5.34375 L 4.03125 7.21875 L 5.96875 16 L 4.03125 24.78125 L 3.59375 26.65625 L 5.375 25.9375 L 27.375 16.9375 L 29.65625 16 L 27.375 15.0625 L 5.375 6.0625 L 3.59375 5.34375 z M 6.375 8.65625 L 21.90625 15 L 7.78125 15 L 6.375 8.65625 z M 7.78125 17 L 21.90625 17 L 6.375 23.34375 L 7.78125 17 z"
    ></path>
  </svg>
);
