import React from "react";

export const InfoCard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col justify-between rounded-lg bg-white p-6 shadow-xl ring-1 ring-panelborder">
      {children}
    </div>
  );
};

export default InfoCard;
