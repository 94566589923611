import React, { useEffect, useState } from "react";
import {
  Answers as IAnswers,
  SurveyItem,
  SurveyPage as ISurveyPage,
  SurveyQuestion,
} from "@careerinsight/applib-common/entity";
import { applyToAllItems, applyToItemAndChildren, resolveSurveyDefinitionTemplateValues } from "@careerinsight/applib-common";
import { fetchSurveyAndResponse, saveAnswers } from "../../repo";
import { setQueryParameter } from "../../util.js";

/**
 * SurveyContext
 */
export const SurveyContext = React.createContext<{
  surveyDefinition: ISurveyPage[];
  answers: IAnswers;
  pageNumber: number;
  previousPage: () => void;
  nextPage: () => boolean;
  isLoading: boolean;
  error?: Error;
  setAnswer: (surveyQuestion: SurveyQuestion, value: any) => void;
  flushPendingAnswers: () => void;
  hasPendingAnswers: () => boolean;
  setSurveyComplete: () => void;

  /**
   * How far are we through the survey as value between 0 and 100 inclusive
   */
  percentComplete: number;
}>(null as any);

/**
 * How long should we wait - from the most recent answer 'event' - before saving any pending answers to the server?
 */
const WRITE_CACHE_TIMEOUT = 2000;

/**
 * If there are more than this many answers waiting to be saved, then don't bother waiting
 */
const MAX_ANSWER_COUNT = 5;

export const SurveyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [answers, setAnswers] = React.useState<IAnswers>({});
  const [pageNumber, setPageNumber] = useState(0);
  const [percentComplete, setPercentComplete] = useState(0);
  const [percentageMap, setPercentageMap] = useState<Record<string, number>>({});
  const [surveyDefinition, setSurveyDefinition] = React.useState<ISurveyPage[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Error>();
  const [writeCache, setWriteCache] = React.useState<IAnswers>({});
  const [writeCacheTimeout, setWriteCacheTimeout] = React.useState<ReturnType<typeof setTimeout>>();

  const urlParams = new URLSearchParams(window.location.search);
  const surveyInstanceSecretKey = urlParams.get("k")!;

  /**
   * Load the survey definition and (any) existing answers
   */
  useEffect(function startUp() {
    if (!surveyInstanceSecretKey) {
      setError(new Error("Missing survey key"));
      setIsLoading(false);
    } else {
      (async () => {
        try {
          const response = await fetchSurveyAndResponse(surveyInstanceSecretKey);
          setAnswers(response.surveyResponse);
          setSurveyDefinition(resolveSurveyDefinitionTemplateValues(response.surveyDefinition, response.model));

          // Compile percentageMap
          const nextPercentageMap = computePercentageMap(response.surveyDefinition);
          setPercentageMap(nextPercentageMap);

          // Compile questionKeyToUniqueId
          const questionKeyToUniqueId: Record<string, string> = {};
          applyToAllItems((item: SurveyItem) => {
            if ("questionKey" in item) questionKeyToUniqueId[item.questionKey] = item.uniqueId;
          }, response.surveyDefinition);

          // Set the percent bar if the survey is already (partially) filled out
          if (Number.isInteger(response.surveyResponse["percentageComplete"])) {
            setPercentComplete(Number(response.surveyResponse["percentageComplete"]));
          } else {
            // This code is unnecessary. If any answer has been set, then percentageComplete will come from the server
            let maxPercent = 0;
            for (const questionKey in response.surveyResponse) {
              const uniqueId = questionKeyToUniqueId[questionKey]!;
              const p = nextPercentageMap[uniqueId] || 0;
              if (p > maxPercent) maxPercent = p;
            }
            setPercentComplete(maxPercent);
          }

          // Process query string parameters
          const inputUrl = new URL(window.location.href);
          const inputParams = new URLSearchParams(inputUrl.search);

          if (inputParams.has("p")) {
            // Lookup and set the page number
            const inputPageId = inputParams.get("p");
            for (let i = 0; i < response.surveyDefinition.length; i++) {
              const p = response.surveyDefinition[i]!;
              if (p.uniqueId === inputPageId) {
                setPageNumber(i);

                // Update the percentage complete based on the page number
                setPercentComplete((current) => {
                  const pagePercentage = nextPercentageMap[inputPageId];
                  if (!pagePercentage) return current;
                  return current < pagePercentage ? pagePercentage : current;
                });
              }
            }
          } else {
            // Add the ID of page 0 to the URL
            if (response.surveyDefinition.length) {
              const pageId = response.surveyDefinition[0]!.uniqueId;
              setQueryParameter("p", pageId, true);
            }
          }
        } catch (e) {
          setError(e as Error);
        }

        setIsLoading(false);
      })();
    }

    return () => {
      // Clean-up code
    };
  }, []);

  /**
   * Handle browser back, forward buttons
   */
  useEffect(
    function handleBrowserNavigationButton() {
      const popStateHandler = () => {
        // Get the page ID from the URL
        const inputUrl = new URL(window.location.href);
        const inputParams = new URLSearchParams(inputUrl.search);
        const inputPageId = inputParams.get("p");

        // Lookup the corresponding page number
        for (let i = 0; i < surveyDefinition.length; i++) {
          const p = surveyDefinition[i]!;
          if (p.uniqueId === inputPageId) {
            // Set the page number
            setPageNumber(i);
          }
        }
      };

      window.addEventListener("popstate", popStateHandler);
      return () => {
        window.removeEventListener("popstate", popStateHandler);
      };
    },
    [surveyDefinition],
  );

  /**
   * Save the answer to the server, but in a delayed manner
   */
  function persistAnswer(questionKey: string, value: unknown) {
    writeCache[questionKey] = value;
    if (writeCacheTimeout) clearTimeout(writeCacheTimeout);
    const millisecondsDelay = Object.keys(writeCache).length > MAX_ANSWER_COUNT ? 0 : WRITE_CACHE_TIMEOUT;
    const timeout = setTimeout(async () => await flushPendingAnswers(), millisecondsDelay);
    setWriteCacheTimeout(timeout);
  }

  /**
   * Records an individual answer and also persists it to the server. It is assumed that the value has
   * passed validation.
   */
  function setAnswer(surveyQuestion: SurveyQuestion, value: unknown) {
    // Save the answer to local state
    setAnswers((current) => ({ ...current, [surveyQuestion.questionKey]: value }));

    // Persist the answer to the server
    persistAnswer(surveyQuestion.questionKey, value);

    // Update the percent complete
    setPercentComplete((current) => {
      const answerPercentComplete = percentageMap[surveyQuestion.uniqueId] || 0;
      const percentageComplete = answerPercentComplete > current ? answerPercentComplete : current;
      writeCache["percentageComplete"] = percentageComplete;
      return percentageComplete;
    });
  }

  /**
   * Persists any pending answers to the server without waiting
   */
  async function flushPendingAnswers() {
    if (writeCacheTimeout) clearTimeout(writeCacheTimeout);
    if (!Object.keys(writeCache).length) return;
    const answersToSave = { ...writeCache };
    setWriteCache({});

    // Take no action if we're in preview mode
    if (answers["SYS_PREVIEW_MODE"] === true) return;

    try {
      await saveAnswers(surveyInstanceSecretKey, answersToSave); // Save to server
    } catch (e) {
      // Put the failed values back into writeCache
      setWriteCache((current) => ({ ...current, ...answersToSave }));

      // TODO: This is terrible error handling; but will be resolved as part of #94
      alert((e as Error).message);
    }
  }

  /**
   * Increments the page number and writes the new page ID to the URL
   * @return true if we've arrived at the last page
   */
  function nextPage() {
    const nextPageNumber = pageNumber + 1;
    setPageNumber(nextPageNumber);

    // Increment progress bar
    const pageId = surveyDefinition[nextPageNumber]!.uniqueId;
    const pagePercentage = percentageMap[pageId];
    if (pagePercentage && percentComplete < pagePercentage) {
      setPercentComplete(pagePercentage);
    }

    // Update URL
    setQueryParameter("p", pageId);

    // Return true if we've arrived at the last page
    return nextPageNumber === surveyDefinition.length - 1;
  }

  /**
   * Decrements the page number and writes the new page ID to the URL
   */
  function previousPage() {
    // Update URL, and let handleBrowserNavigationButton effect take care
    window.history.back();
  }

  return (
    <SurveyContext.Provider
      value={{
        pageNumber,
        previousPage,
        nextPage,
        answers,
        setAnswer,
        flushPendingAnswers,
        hasPendingAnswers: () => Object.keys(writeCache).length > 0,
        surveyDefinition,
        isLoading,
        error,
        percentComplete,
        setSurveyComplete: () => persistAnswer("percentageComplete", 100),
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

/**
 * Computes a lookup of item.uniqueId:string -> percentage-complete:number
 *
 * When given a survey item.uniqueId, this map returns survey percentage complete as an integer - useful for updating
 * a UI visual.
 * @param surveyPages
 */
function computePercentageMap(surveyPages: ISurveyPage[]) {
  const percentageMap: Record<string, number> = {};
  const uniqueIdArr: string[] = [];

  // Iterate over pages
  for (const page of surveyPages) {
    // Add page ID so we can use next-page to advance the percentage
    uniqueIdArr.push(page.uniqueId);

    // Also add all questionKey
    for (const item of page.surveyItems) {
      applyToItemAndChildren((item: SurveyItem) => {
        if ("questionKey" in item) uniqueIdArr.push(item.uniqueId);
      }, item);
    }
  }

  // Create uniqueId => percentage map
  const itemCount = uniqueIdArr.length;
  const rate = 100 / (itemCount - 1);
  for (let i = 0; i < itemCount; i++) {
    const uniqueId = uniqueIdArr[i]!;
    percentageMap[uniqueId] = Math.round(i * rate);
  }

  return percentageMap;
}
