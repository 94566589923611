import { NominatedRater } from "@careerinsight/applib-common/zod";
import { appSettings, getErrorMessage } from "../index";
import { RaterSurveyInstanceSummary } from "@careerinsight/applib-common/entity";

export async function deleteRater(surveyInstanceSecretKey: string, raterSurveyInstanceUuid: string) {
  const url = `${appSettings.surveyApi}/surveys/${surveyInstanceSecretKey}/raters/${raterSurveyInstanceUuid}`;

  return await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updateRater(
  surveyInstanceSecretKey: string,
  props: {
    forename: string;
    surname: string;
    raterType: string;
    raterSurveyInstanceUuid: string;
  },
) {
  const url = `${appSettings.surveyApi}/surveys/${surveyInstanceSecretKey}/raters/${props.raterSurveyInstanceUuid}`;

  return await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(props),
  });
}

export const sendReminderMessage = async (
  surveyInstanceSecretKey: string,
  props: {
    message: string;
    raterSurveyInstanceUuid: string;
  },
) => {
  const url = `${appSettings.surveyApi}/surveys/${surveyInstanceSecretKey}/raters/${props.raterSurveyInstanceUuid}/send-reminder-message`;

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(props),
  });
};

export const submitNominatedRater = async (surveyInstanceSecretKey: string, nominatedRater: NominatedRater) => {
  const url = `${appSettings.surveyApi}/surveys/${surveyInstanceSecretKey}/raters`;

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(nominatedRater),
  });
};

export const getRaterSurvey = async (
  surveyInstanceSecretKey: string,
  raterSurveyInstanceUuid: string,
): Promise<RaterSurveyInstanceSummary & { emailAddresses: Array<string> }> => {
  const url = appSettings.surveyApi + "/surveys/" + surveyInstanceSecretKey + "/raters/" + raterSurveyInstanceUuid;
  const response = await fetch(url);
  if (response.ok) return await response.json();
  throw new Error(getErrorMessage(await response.json()));
};
