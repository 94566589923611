import { InfoCard } from "../InfoCard";
import React, { useEffect, useState } from "react";
import { RespondentHomepageResponse } from "@careerinsight/applib-common/entity";
import { RaterProgressTable } from "../RaterProgressTable";
import { RaterSurveyType } from "../../types";
import { raterTypeToLabelMap } from "../../util";

export const RaterProgressCard: React.FC<{ model: RespondentHomepageResponse }> = ({ model }) => {
  const [raterSurveys, setRaterSurveys] = useState<Array<RaterSurveyType>>([]);
  useEffect(() => setRaterSurveys(compileRaterSurveys(model)), [model]);

  return (
    <InfoCard>
      <h3 className="font-semibold text-xl">Rater progress</h3>

      <p className="my-4">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid animi consectetur dolorem, ea eligendi illo
        illum in, laudantium maiores minus placeat quibusdam recusandae voluptate?
      </p>

      <div className="overflow-hidden shadow ring-1 ring-dark rounded-lg">
        <RaterProgressTable raterList={raterSurveys} />
      </div>
    </InfoCard>
  );
};

/*
 * Private helper methods
 */

/**
 * Returns model.raterSurveyInstances but ordered by the raterType as stated in model.requiredRaters
 */
function compileRaterSurveys(model: RespondentHomepageResponse) {
  const raterSurveyInstances = model.raterSurveyInstances!;
  const requiredRaters = model.requiredRaters!;
  const raterLabels = raterTypeToLabelMap(model.requiredRaters!);

  // Add the label to each raterSurveyInstance
  const raterSurveys: Array<RaterSurveyType> = [];
  raterSurveyInstances.forEach((raterSurveyInstance) => {
    raterSurveys.push({
      ...raterSurveyInstance,
      label: raterLabels.get(raterSurveyInstance.raterType!) || "",
      emailAddresses: [],
    });
  });

  return raterSurveys.sort((a, b) => {
    const aIndex = requiredRaters.findIndex((rater) => rater.raterType === a.raterType);
    const bIndex = requiredRaters.findIndex((rater) => rater.raterType === b.raterType);
    return aIndex - bIndex;
  });
}

export default RaterProgressCard;
