import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router";
import { compileRequiredRaterList, getErrorMessage, raterTypeToLabelMap, RespondentHomepageContext } from "../../index";
import { Floppy, Spinner, Trash } from "../../icons";
import { RaterSurveyType } from "../../types";
import { SurveyStatusBadge } from "../SurveyStatusBadge";
import { AlertBox } from "../AlertBox";
import { SurveyLoading } from "../SurveyLoading";
import { getRaterSurvey } from "../../repo";

type FormData = {
  forename: string;
  surname: string;
  raterType: string;
};

export const EditRater: React.FC = ({}) => {
  const context = useContext(RespondentHomepageContext);
  const [validationErrors, setValidationErrors] = useState<FormData>({ forename: "", surname: "", raterType: "" });
  const [submitted, setSubmitted] = useState(false);
  const [raterSurvey, setRaterSurvey] = useState<RaterSurveyType>();
  const [loadedRaterSurvey, setLoadedRaterSurvey] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const navigate = useNavigate();
  const model = context.model!;
  const [searchParams] = useSearchParams();
  const surveyInstanceSecretKey = searchParams.get("k")!;
  const raterSurveyInstanceUuid = searchParams.get("s") || "";
  const [formData, setFormData] = useState<FormData>({
    forename: "",
    surname: "",
    raterType: "",
  });

  useEffect(() => {
    (async () => {
      const raterSurveyInstance = await getRaterSurvey(surveyInstanceSecretKey, raterSurveyInstanceUuid);

      if (!raterSurveyInstance) {
        setRaterSurvey(undefined);
        return;
      }

      const raterLabels = raterTypeToLabelMap(model.requiredRaters!);

      setRaterSurvey({
        ...raterSurveyInstance,
        label: raterLabels.get(raterSurveyInstance.raterType!) || "",
      });

      setFormData({
        forename: raterSurveyInstance.forename,
        surname: raterSurveyInstance.surname,
        raterType: raterSurveyInstance.raterType!,
      });

      setLoadedRaterSurvey(true);
    })();
  }, [raterSurveyInstanceUuid]);

  if (!raterSurveyInstanceUuid) return <pre>Rater survey ID is missing</pre>;
  if (!loadedRaterSurvey) return <SurveyLoading />;
  if (!raterSurvey) return <pre>Rater survey not found</pre>;

  const onChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (event) => {
    const { name, value } = event.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    if (submitted) validate(newFormData);
  };

  const validate = (formData: FormData) => {
    const newErrors = { forename: "", surname: "", raterType: "" };
    if (!formData.forename) newErrors.forename = "A value is required";
    if (!formData.surname) newErrors.surname = "A value is required";

    setValidationErrors(newErrors);
    return newErrors;
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    setSubmitted(true);
    event.preventDefault();
    const errors = validate(formData);
    if (errors.forename || errors.surname) return;

    setIsLoading("save");
    const response = await context.updateRater({ ...formData, raterSurveyInstanceUuid });

    if (response.status !== 200) {
      const content = await response.json();
      setServerErrorMessage(getErrorMessage(content));
      setIsLoading("");
    } else {
      await navigate("/homepage?k=" + surveyInstanceSecretKey);
      setIsLoading("");
    }
  };

  const deleteRater: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();
    const message =
      "You are about to delete this rater’s survey. This action cannot be undone! Are you sure you wish to proceed?";
    if (!confirm(message)) return;

    setIsLoading("delete");
    const response = await context.deleteRater(raterSurveyInstanceUuid);

    if (response.status !== 200) {
      const content = await response.json();
      setServerErrorMessage(getErrorMessage(content));
      setIsLoading("");
    } else {
      await navigate("/homepage?k=" + surveyInstanceSecretKey);
      setIsLoading("");
    }
  };

  // Compile the list of possible rater types
  const raterTypes: Array<{ label: string; raterType: string }> = [];
  const requiredRaterList = compileRequiredRaterList(model);
  for (const rr of model.requiredRaters!) {
    if (requiredRaterList.find((r) => r.raterType === rr.raterType) || rr.raterType === raterSurvey.raterType) {
      const label = rr.label.charAt(0).toUpperCase() + rr.label.slice(1);
      raterTypes.push({ label, raterType: rr.raterType });
    }
  }

  return (
    <>
      <h1 className="text-3xl font-bold">Edit rater</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor dolore omnis voluptatibus.</p>
      {serverErrorMessage && <AlertBox>{serverErrorMessage}</AlertBox>}

      <form
        className="bg-white ring-1 shadow-xs ring-secondary-border rounded-lg md:col-span-2"
        onSubmit={onSubmit}
        noValidate={true}
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {raterSurvey.emailAddresses.map((address) => (
              <div className="sm:col-span-full" key={address}>
                <label htmlFor="forename" className="block text-sm/6 font-medium text-dark">
                  Email address
                </label>

                <div className="mt-2 overflow-x-auto">
                  <code>{address}</code>
                </div>
              </div>
            ))}

            <div className="sm:col-span-3">
              <label htmlFor="forename" className="block text-sm/6 font-medium text-dark">
                First name
              </label>

              <div className="mt-2">
                <input
                  type="text"
                  name="forename"
                  id="forename"
                  className={`block w-full rounded-md bg-white px-3 py-1.5 border focus:outline-2 focus:outline-primary disabled:bg-secondary-light disabled:text-secondary ${
                    validationErrors.forename
                      ? "border-danger text-danger"
                      : "bg-secondary-light/20 border-secondary-border"
                  }`}
                  value={formData.forename}
                  onChange={onChange}
                  disabled={!!isLoading}
                />
                {validationErrors.forename && (
                  <small className="text-danger font-semibold">{validationErrors.forename}</small>
                )}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="surname" className="block text-sm/6 font-medium text-dark">
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  className={`block w-full rounded-md bg-white px-3 py-1.5 border focus:outline-2 focus:outline-primary disabled:bg-secondary-light disabled:text-secondary ${
                    validationErrors.surname ? "border-danger text-danger" : "border-secondary-border"
                  }`}
                  value={formData.surname}
                  onChange={onChange}
                  disabled={!!isLoading}
                />
                {validationErrors.surname && (
                  <small className="text-danger font-semibold">{validationErrors.surname}</small>
                )}{" "}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="raterType" className="block text-sm/6 font-medium text-gray-900">
                Rater type
              </label>
              <div className="mt-2">
                <select
                  className="border rounded-md block w-full p-2 bg-white border-secondary-border focus:outline-2 focus:outline-primary disabled:bg-secondary-light disabled:text-secondary"
                  id="raterType"
                  name="raterType"
                  value={formData.raterType}
                  onChange={onChange}
                  disabled={!!isLoading}
                >
                  {raterTypes.map((option) => (
                    <option key={option.raterType} value={option.raterType}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="surname" className="block text-sm/6 font-medium text-dark">
                Status
              </label>
              <div className="mt-2">
                <SurveyStatusBadge rater={raterSurvey} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4 border-t border-secondary-border p-4 flex-wrap">
          <button type="submit" className="btn btn-primary relative" disabled={!!isLoading}>
            <div
              className={`w-full absolute left-0 flex justify-center ${isLoading === "save" ? "visible" : "invisible"}`}
            >
              <Spinner className="w-6 animate-spin" />
            </div>

            <div className={`flex items-center gap-2 ${isLoading === "save" ? "invisible" : "visible"}`}>
              <Floppy className="w-5" />
              <div>Save</div>
            </div>
          </button>

          <button type="button" onClick={deleteRater} className="btn btn-danger relative" disabled={!!isLoading}>
            <div
              className={`w-full absolute left-0 flex justify-center ${
                isLoading === "delete" ? "visible" : "invisible"
              }`}
            >
              <Spinner className="w-6 animate-spin" />
            </div>

            <div className={`flex items-center gap-2 ${isLoading === "delete" ? "invisible" : "visible"}`}>
              <Trash className="w-5" />
              <div>Delete rater</div>
            </div>
          </button>

          <Link
            to={{ pathname: "/homepage", search: "?k=" + surveyInstanceSecretKey }}
            className={`btn btn-secondary ${isLoading ? "disabled" : ""}`}
          >
            Cancel
          </Link>
        </div>
      </form>
    </>
  );
};

export default EditRater;
