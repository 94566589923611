import React, { useContext } from "react";
import { RespondentHomepageContext, SurveyError, SurveyHeader, SurveyLoading } from "../../index";

export const HomepageWrapper: React.FC<{
  children: React.ReactNode;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}> = ({ children, header, footer }) => {
  window.scrollTo({ top: 0, behavior: "instant" });
  const { model, isLoading, error } = useContext(RespondentHomepageContext);

  let content;

  if (isLoading) {
    content = <SurveyLoading />;
  } else if (error) {
    content = <SurveyError errorMessage={error?.message ? error?.message : "Unknown error"} />;
  } else if (model) {
    content = children;
  }

  return (
    <div className="sm:bg-background">
      <div className="max-w-4xl mx-auto">
        <div className="min-h-screen sm:pt-3 pb-12 mx-3">
          <div className="pb-3">{header || <SurveyHeader />}</div>

          <div className="pb-3">
            <div className="sm:bg-light sm:shadow-xl sm:rounded-lg sm:ring-1 sm:ring-panelborder">
              <div className="p-1 sm:p-6 space-y-7">{content}</div>
            </div>
          </div>

          {footer && footer}
        </div>
      </div>
    </div>
  );
};

export default HomepageWrapper;
