import { SurveyPage } from "./entity";
import { applyToAllItems } from "./apply-to-all-survey-items";
import _ from "lodash";

export const resolveSurveyDefinitionTemplateValues = (
  surveyDefinition: Array<SurveyPage>,
  model: Record<string, unknown>,
): Array<SurveyPage> => {
  const def = _.cloneDeep(surveyDefinition);

  applyToAllItems((item) => {
    if ("html" in item) item.html = resolveTemplateValues(item.html, model);
    if ("label" in item && item.label) item.label = resolveTemplateValues(item.label, model);

    if ("titleMap" in item) {
      item.titleMap.forEach((titleMap) => {
        titleMap.label = resolveTemplateValues(titleMap.label, model);
      });
    }
  }, def);

  return def;
};

export const resolveTemplateValues = (str: string, model: Record<string, unknown>) => {
  // Replace all instances of ${key} with the value of model[key]
  return str.replace(/\${([^}]+)}/g, (_, key) => {
    return (
      // The key may contain a period, which means that we look up values from the model recursively
      key.split(".").reduce((acc: Record<string, unknown>, key: string) => (acc ? acc[key] : undefined), model) ||
      // If the value wasn't resolved, then return the original string
      "${" + key + "}"
    );
  });
};
